<template>
    <div class="pagination-wrapper">
        <div class="pagination-left">
            <label for="recordsPerPage">Records per page:</label>
            <select id="recordsPerPage" v-model="recordsPerPage" @change="onRecordsPerPageChange">
                <option v-for="option in recordsPerPageOptions" :key="option" :value="option">
                    {{ option }}
                </option>
            </select>
            <span class="showing-range">
                Showing: {{ showingRange }} of {{ totalRecords }}
            </span>
        </div>
        <ul class="pagination">
            <li class="pagination-item">
                <button type="button" @click="onClickFirstPage" :disabled="isInFirstPage">
                    << </button>
            </li>
            <li class="pagination-item">
                <button type="button" @click="onClickPreviousPage" :disabled="isInFirstPage">
                    < </button>
            </li>
            <!-- Visible Buttons Start -->
            <li v-for="page in pages" :key="page.name" class="pagination-item">
                <button type="button" @click="onClickPage(page.name)" :disabled="page.isDisabled"
                    :class="{ active: isPageActive(page.name) }">
                    {{ page.name }}
                </button>
            </li>
            <!-- Visible Buttons End -->
            <li class="pagination-item">
                <button type="button" @click="onClickNextPage" :disabled="isInLastPage">
                    >
                </button>
            </li>
            <li class="pagination-item">
                <button type="button" @click="onClickLastPage" :disabled="isInLastPage">
                    >> 
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        maxVisibleButtons: {
            type: Number,
            required: false,
            default: 3
        },
        totalPages: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        },
        totalRecords: {
            type: Number,
            required: true
        },
        defaultRecordsPerPage: {
            type: Number,
            required: false,
        },
        recordsPerPageOptions: {
            type: Array,
            required: false,
            default: () => [5, 10, 20, 30]
        }
    },
    data() {
        return {
            recordsPerPage: this.perPage
        };
    },
    computed: {
        startPage() {
            if (this.currentPage === 1) {
                return 1;
            }
            if (this.currentPage === this.totalPages) {
                let startPageNo = this.totalPages - this.maxVisibleButtons + 1;
                return startPageNo > 0 ? startPageNo : 1;
            }
            return this.currentPage - 1;
        },
        pages() {
            const range = [];
            for (
                let i = this.startPage; i <= Math.min(this.startPage + (this.maxVisibleButtons - 1), this.totalPages); i++
            ) {
                range.push({
                    name: i,
                    isDisabled: i === this.currentPage
                });
            }
            return range;
        },
        isInFirstPage() {
            return this.currentPage === 1;
        },
        isInLastPage() {
            return this.currentPage === this.totalPages;
        },
        showingRange() {
            const start = (this.currentPage - 1) * this.recordsPerPage + 1;
            const end = Math.min(this.currentPage * this.recordsPerPage, this.totalRecords);
            return `${start}-${end}`;
        }
    },
    methods: {
        onClickFirstPage() {
            this.$emit('pagechanged', 1);
        },
        onClickPreviousPage() {
            this.$emit('pagechanged', this.currentPage - 1);
        },
        onClickPage(page) {
            this.$emit('pagechanged', page);
        },
        onClickNextPage() {
            this.$emit('pagechanged', this.currentPage + 1);
        },
        onClickLastPage() {
            this.$emit('pagechanged', this.totalPages);
        },
        isPageActive(page) {
            return this.currentPage === page;
        },
        onRecordsPerPageChange() {
            this.$emit('recordCount', this.recordsPerPage);
            this.$emit('pagechanged', 1);
        }
    }
};
</script>

<style scoped>
.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.pagination {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}

.pagination-item {
    margin: 0;
}

.pagination-item button {
    background-color: white;
    color: #cd7838;
    border: none;
    border-radius: 10px;
    padding: 0px 10px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
    outline: none;
}

.pagination-item button:disabled {
    background-color: white;
    cursor: not-allowed;
    color: lightgray;
}

.pagination-item button.active {
    text-decoration: underline;
    font-weight: bold;
    color: #cd7838;
}

.pagination-item button:hover:not(:disabled) {
    transform: scale(1.1);
}
</style>
