<template>

  <div class="page-container">
    <div class="login-container">
      <div v-if="loginErrorMessage" class="alert alert-danger" role="alert">
        {{ loginErrorMessage }}
      </div>
      <button @click="login('azure')" class="login-btn microsoft-btn">
        <img src="../assets/icons//microsoft.svg" alt="Microsoft Logo" class="login-icon" />
        Login with Microsoft
      </button>
      <button @click="login('github')" class="login-btn github-btn">
        <img src="../assets/icons/github.svg" alt="GitHub Logo" class="login-icon" />
        Login with GitHub
      </button>
      <button @click="login('linkedin')" class="login-btn linkedin-btn">
        <img src="../assets/icons/linkedin.svg" alt="LinkedIn Logo" class="login-icon" />
        Login with LinkedIn
      </button>
    </div>
  </div>
</template>

<script>
import { pageViews } from "../utils/util.js";


import "vue3-toastify/dist/index.css";

export default {
  authSource: "",
  name: pageViews.LOGINVIEW,
  data() {
    return {
      loginErrorMessage: ""
    };
  },
  methods: {
    async login(source) {
      window.location.href = this.$acclConfigs['authentication-api-url'] + "/login?authSource=" + source

       /*this.$router.push({
         path: "/callback",
         query: {
           //data : "eyJzdGF0dXMiOiAic3VjY2VzcyIsICJpZCI6ICJzYXNpZGFyZW4iLCAibmFtZSI6ICJTYXNpZGFyZW4gRGluYWthcmFuIn0=" //encoded Basic Data
             //data : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImdhbmVzaHByYWJodXMiLCJuYW1lIjoiR2FuZXNoIFByYWJodSIsInN0YXR1cyI6InN1Y2Nlc3MiLCJhdXRoU291cmNlIjoiR0lUSFVCIn0.TaN2iRdihR2lJnVoqaJcY_DUwUP0obijZmOLMc_d4_c" // encoded JWT Data
             
             //User Role
             //data: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImdwcmFiaHVAcHN5bmNvcGF0ZS5jb20iLCJuYW1lIjoiR2FuZXNoIFByYWJodSIsInN0YXR1cyI6InN1Y2Nlc3MiLCJhdXRoU291cmNlIjoiQVpVUkUiLCJleHBpcnlfdHMiOiIyMDI0LTEwLTAyIDEyOjQwOjE5Iiwicm9sZSI6InVzZXIifQ.-heKtSvpz_QLd9DUxAuPFD0jlRihOzMt2sy23AXKKVI"
             
             //Admin Role
             data: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImdhbmVzaHByYWJodXMiLCJuYW1lIjoiR2FuZXNoIFByYWJodSIsInN0YXR1cyI6InN1Y2Nlc3MiLCJhdXRoU291cmNlIjoiR0lUSFVCIiwiZXhwaXJ5X3RzIjoiMjAyNC0xMi0wOSAwNDowNTo1MSIsInJvbGUiOiJhZG1pbiJ9.1RhP2fJ-86aPgVeJTZd7ChnB-SmQICfSC70gppi77ds"
 
             //SuperAdmin
           // data: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Im1yYWZpa0Bwc3luY29wYXRlLmNvbSIsIm5hbWUiOiJNb2hhbWVkIFJhZmlrIiwic3RhdHVzIjoic3VjY2VzcyIsImF1dGhTb3VyY2UiOiJBWlVSRSIsImV4cGlyeV90cyI6IjIwMjQtMDktMjcgMDk6MTc6MzkiLCJyb2xlIjoic3VwZXJhZG1pbiJ9.tLF0s2p0eAyo9TA5G-ZZR7fb5xMchaagVWt5Z5qrjN8"
           //data: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InNkaW5ha2FyYW5AcHN5bmNvcGF0ZS5jb20iLCJuYW1lIjoiU2FzaWRhcmVuIERpbmFrYXJhbiIsInN0YXR1cyI6InN1Y2Nlc3MiLCJhdXRoU291cmNlIjoiQVpVUkUifQ.Al1IgqLoLsgEYmBTZlCr0COShKxmfn1JPRfC5Yp-6To"
 
           //De-activated user
           //data: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImdwcmFiaHVAcHN5bmNvcGF0ZS5jb20iLCJzdGF0dXMiOiJkaXNhYmxlZCIsIm1lc3NhZ2UiOiJZb3VyIGFjY291bnQgaXMgY3VycmVubHkgZGUtYWN0aXZhdGVkLiBQbGVhc2UgY2hlY2sgd2l0aCBhZG1pbiJ9.U315MfkNPvH2j3LTudjGJDh4K4IGY3ul4t0df2t5WQA"
         },
       });*/

    },
    verifyValidUser() {
      const params = new URLSearchParams(window.location.search);
      console.log("Data ", params.get("error"));
      this.loginErrorMessage = params.get("error")
    }
  },
  mounted() {
    this.verifyValidUser();
  }
};
</script>

<style scoped>
.login-sub-container {
  margin-top: 15%;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  margin: 0;
}

.page-container {
  /* background: url('../assets/bg/blue-bg-1.jpg'); */
  background-color: #03122F;
  display: flex;


  align-items: center;
  height: 84vh;
  padding: 20px;
}

.login-container {
  margin: 0 auto;
  background-color: white;

  /* padding: 20px; */
  padding: 2% 0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 20%;
}

.login-container:hover {
  box-shadow: 0 0 20px #09ffff;
  /* text-shadow: 0 0 5px #ffee10; */
}

.login-container h2 {
  margin-bottom: 20px;
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding: 3%;
  margin: 10px auto;
  border: 1px solid transparent;
  border-radius: 7px;
  font-size: 16px;
  cursor: pointer;
  color: black;
  text-align: left;
}

.login-btn:hover {
  box-shadow: 0 0 20px #04183B;
}

.login-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.microsoft-btn {
  border-color: black;
  background-color: white;
}

.github-btn {
  background-color: white;
  border-color: #333;
}

.linkedin-btn {
  background-color: white;
  border-color: black;
}

.favicon {
  height: 30px;
  width: 30px;
}

.login-btn:hover {
  opacity: 0.9;
}
</style>