<template>
    <div class="nav">
        <router-link :to="{ name: pageViews.KAFKACONFIGGENERATORVIEW }" class="link" >Kafka Config Generator</router-link>
        <router-link :to="{ name: pageViews.SCHEMACONVERTERVIEW }" class="link" >Schema Converter</router-link>
        <router-link :to="{ name: pageViews.CONVERTERVIEW }" class="link" >XSLT Converter</router-link>
        <router-link :to="{ name: pageViews.CONNECTOR_CONFIG_GENERATOR_VIEW }" class="link" >Connector Config Generator</router-link>
    </div>
    
    
</template>

<script>
import { pageViews, app_mode } from "../utils/util";

    export default {
        name: pageViews.INDEPENDENT_ACC_VIEW,
        data() {
            return {
                pageViews: Object.freeze(pageViews),
            }
        },
        
    }
    

</script>

<style scoped>
.nav {
    display: flex;
    justify-content: flex-start;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.link {
    padding: 10px 15px;
    margin-right: 10px;
    text-decoration: none;
    color: #da6a26; /* Initial font color */
    font-weight: 500;
    border: 1px solid transparent; /* Transparent border */
    border-radius: 12px; /* Rounded borders */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.link:hover {
    background-color: #f09f6d; /* Hover background */
    color: #fff; /* Hover font color */
}

.link.router-link-active {
    background-color: #da6a26; /* Active background */
    color: #fff; /* Active font color */
    border-color: #da6a26; /* Active border color */
    border-radius: 12px; /* Maintain rounded borders */
    text-decoration: underline; /* Underline the font */
}

</style>